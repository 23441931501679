import {
    Elm
} from '../newsfeed/Profile';

document.addEventListener('turbolinks:load', () => {
    const target = document.getElementById('profile-elm-container');
    const relatedArticlesEl = document.getElementById('related-articles');
    var relatedArticles = [0]; // cheap way to include no related articles

    if (relatedArticlesEl != null) {
        if (relatedArticlesEl.dataset.relatedArticles != null) {
            var _related = JSON.parse(relatedArticlesEl.dataset.relatedArticles);
            if (_related.length > 0) {
                relatedArticles = _related;
            }
        }
    }

    if (target != null) {
        const app = Elm.Profile.init({
            node: target,
            flags: { dev: false, postIds: relatedArticles }
        });

        app.ports.openURLInNewTab.subscribe(function (url) {
            window.open(url);
        });
    }
});
